<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Prise en charge
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="flex justify-center">
              <easy-spinner
                  v-if="charge"
                  type="dots"
                  size="40"
              />
            </div>

            <div v-if="allInfo.length > 0">
              <div class="text-left text-c12">
                Maison d’assurance/partenarie
              </div>

              <multiselect
                  :option="allInfo"
                  background="white"
                  class="mt-2"
                  width="36%"
                  size="14px"
                  value-t="Sélectionnez la compagnie concernée"
                  @info="retourMoyen"
              />
            </div>

            <div class="mt-6">
              <div class="text-left text-c12">
                Référence du dossier
              </div>

              <div class="mt-2">
                <inputo
                    @info="retourRef"
                />
              </div>
            </div>

            <div class="mt-6">
              <div class="text-left text-c12">
                Valeur des Verres
              </div>

              <div class="mt-2">
                <inputo
                    type="number"
                    @info="retourVerre"
                />
              </div>
            </div>

            <div class="mt-6">
              <div class="text-left text-c12">
                Valeur de la monture
              </div>

              <div class="mt-2">
                <inputo
                    type="number"
                    @info="retourMonture"
                />
              </div>
            </div>

<!--            <div class="text-left text-c12 mt-6">-->
<!--              Date d’expiration-->
<!--            </div>-->

<!--            <div class="mt-2">-->
<!--              <datePicker-->
<!--                  :min-date="new Date()"-->
<!--                  @info="retourDate"-->
<!--              />-->
<!--            </div>-->

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import multiselect from '../helper/form/multiselect'
// import datePicker from '../helper/form/datePicker'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'

export default {
  name: 'Success',
  components: {
    PopupBase,
    buton,
    // datePicker,
    multiselect,
    inputo
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donne: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      donnes: null,
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      date: '',
      error: null,
      charge: true,
      allInfo: [],
      position: '',
      verre: null,
      monture: null,
      reference: '',
      compagnie: []
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    this.getCompany()
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    getCompany () {
      http.get(apiroutes.baseURL + apiroutes.allCompany)
          .then(response => {
            this.compagnie = response
            for (let item in response) {
              this.allInfo.push(response[item].name)
            }
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    continuer () {
      this.error = null
      if (this.date.length > 0 && this.position !== 'Sélectionnez la compagnie concernée' && this.reference !== '' && this.monture !== null && this.verre !== null) {
        this.charge = true
        this.fermer()
      } else {
        this.charge = false
        this.error = 'Veuillez remplir les champs pour continuer'
      }
    },

    save (body) {
      http.post(apiroutes.baseURL + apiroutes.createPrice, body)
          .then(response => {
            this.$emit('oga', true)
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourDate (answer) {
      this.date = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    retourMonture (answer) {
      this.monture = answer
    },

    retourMoyen (answer) {
      this.position = answer
    },

    retourVerre (answer) {
      this.verre = answer
    },

    retourRef (answer) {
      this.reference= answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
