<template>
  <div>
    <paiement :activation="activePaye" v-if="activePaye" @oga="retourPaye"/>
    <assurance :activation="activeAssurance" v-if="activeAssurance" @oga="retourAssurance"/>
    <success-paye :activation="activeSuccess" v-if="activeSuccess" @oga="retourSuccess"/>
    <mesure :activation="activeMesure" v-if="activeMesure && devis.estimate.clientMeasures" :donnes="devis.estimate" @oga="retourMesure"/>
    <newmesure :activation="activeMesure" v-if="activeMesure && !devis.estimate.clientMeasures" :donnes="devis.estimate" @oga="retourMesure"/>

    <coupon :activation="activCoupon" :devis="devis" v-if="activCoupon" @oga="activCoupon = false"/>

    <validation :donnes="devis"/>
    <div class="flex mt-6">
      <div class="premier bloc bg-fb rounded-6">
        <div class="p-10">
          <div class="flex">
            <div class="w-4/5" @click="activeDetail =! activeDetail">
              <div
                  v-if="charge && client.length === 0"
                  class="flex justify-center mt-16"
              >
                <easy-spinner
                    type="dots"
                    size="40"
                />
              </div>

              <div class="flex items-center" v-if="devis !== null">
                <label-name :first="devis.estimate.client.name" :second="devis.estimate.client.surname" height="40px" width="40px"/>
                <div class="ml-4 text-c18 text-left">
                  <div>{{ devis.estimate.client.name }} {{ devis.estimate.client.surname }} </div>
                  <div class="text-90 text-c16">
                    {{ devis.estimate.client.phone }} | {{ devis.estimate.client.city }}, {{ devis.estimate.client.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-6" v-if="valueClient !== null">
            <div class="flex items-center">
              <div class="text-c24 font-c6">
                Commande Nº {{devis.reference}}
              </div>
              <div class="ml-4 w-1/6 pl-4 pr-4">
                <bouton
                  label="CSNL"
                  height="23px"
                  color="#FBA705"
                  background="#FFF6E6"
                  radius="5px"
                  size="12px"
                  v-if="devis.state === 'IN_PROGRESS'"
                />
              </div>
            </div>

            <div class="flex mt-6">
              <div class="w-1/2">
                <div class="inputo text-left flex items-center bg-white pl-4 text-c14">
                  <span>{{ startDate.toLocaleDateString() }}</span>
                </div>
                <div class="bg-fb text-90 text-c12 mouv rounded-5 w-1/2 ml-3">
                  Date de la commande
                </div>
              </div>

              <div class="w-1/2 ml-6">
                <div class="inputo text-left flex items-center bg-white pl-4 text-c14">
                  <span v-if="!devis.estimate.meta || !devis.estimate.meta.updateAt">
                    {{ expirationDate(this.devis.created_at) }}
                  </span>
                  <span v-if="devis.estimate.meta && devis.estimate.meta.updateAt">
                    {{ expirationDate(devis.estimate.meta.updateAt) }}
                  </span>
                </div>
                <div class="bg-fb text-90 text-c12 mouv rounded-5 w-2/5 ml-3">
                  Date de livraison
                </div>
              </div>
            </div>

            <div class="mt-4">
              <ordonnance-liste :donnes="devis.estimate"/>
            </div>
          </div>
        </div>


        <div class="diviser" />

        <div class=" p-10"  v-if="valueClient !== null">
          <article-liste :donnes="devis.estimate" />

          <div class="mt-6">
            <medecin :donnes="devis.estimate"/>
          </div>

          <div class="mt-4">
            <assur :donnes="devis.estimate"/>
          </div>
        </div>
        <div class="diviser" />

        <div class="p-10 pt-6 pb-6 text-c14 text-60 text-left">
          <span class="text-black font-c5">Agence: </span>  <span class="ml-2">OHO Les Lunettes, {{ devis.estimate.agency.label }} </span>
          <span class="ml-6 text-black font-c5">Commercial(e): </span>  <span class="ml-2">{{ devis.estimate.seller.fullname }}</span>
        </div>
      </div>

      <div class="second ml-4">
        <div class="w-full">
          <bouton
              label="Voir le coupon"
              height="50px"
              color="#fff"
              background="#3521B5"
              radius="5px"
              size="18px"
              @click="activCoupon = true"
              v-if="devis.estimate.meta.couponUse || devis.coupon"
              class="mb-4"
          />


          <!--          <div class="bloc bg-white rounded-6 pt-6 pb-6 text-left">-->
<!--            <div class="flex text-c18 items-center pl-6 pr-6">-->
<!--              <div class="w-full font-c7">-->
<!--                Paiements-->
<!--              </div>-->
<!--            </div>-->

<!--            <diviser height="0.5px" color="#DDDDDD" class="mt-5"/>-->

<!--            <div class="flex text-c14 mt-4 pl-6 pr-6" v-for="(item, index) in devis.payments" :key="index">-->
<!--              <div class="w-1/2">{{ conversion(item.created_at) }}</div>-->
<!--              <div class="w-1/2 ml-4">-->
<!--                {{item.amount.toLocaleString() }} FR-->
<!--              </div>-->
<!--            </div>-->

<!--            <diviser height="0.5px" color="#DDDDDD" class="mt-5"/>-->

<!--            <div class="flex text-c14 mt-4 pl-6 pr-6">-->
<!--              <div class="w-1/2 font-c5">SOLDE</div>-->
<!--              <div class="w-1/2 ml-4 font-c7">-->
<!--                {{calcule}} FR-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div v-if="devis.estimate.insuranceSupports.length > 0" class="mt-6">
            <div class="ml-2 w-full font-c5 text-c18 text-left">
              Document de prise en charge
            </div>

            <div
                v-for="(item, index) in devis.estimate.insuranceSupports"
                :key="index"
                class="text-left p-4 cursor-pointer flex items-center mt-6"
                :style="{'height': '68px', 'border': '1px dashed #C4C4C4', 'color': '#606060', 'border-radius': '5px', 'background-color': '#FBFBFB'}"
                @click="openUpload(item.meta.referenceUrl)"
            >
              <div
                  class="flex items-center w-full"
              >
                <div class="w-10">
                  <icon
                      :data="icons.document"
                      height="30"
                      width="30"
                      class="cursor-pointer text-center"
                      original
                  />
                </div>

                <div class="ml-2 w-4/5 font-c5 text-c14">
                  {{ item.insurance.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="bloc bg-white rounded-6 p-6 text-left mt-6">
            <div class="flex">
              <div class="text-c16 font-c5 w-full">Mesures du client</div>
              <icon
                  v-if="donnes.state === 'TECHNIQUE'"
                  :data="icons.edit"
                  height="25"
                  width="25"
                  class="cursor-pointer"
                  @click="activeMesure = true"
                  original
              />
            </div>

            <div class="mt-6 text-c14" v-if="devis.estimate.clientMeasures">

              <div class="flex justify-end">
                <div class="w-3/4"/>
                <div class="text-c16 font-c6 text-black mt-6 flex w-1/4 justify-end text-right">
                  <div class="w-1/2">OD</div>
                  <div class="w-1/2 ml-4">OG</div>
                </div>
              </div>

              <div class="mt-6 text-c14">
                <div class="flex items-center">
                  <div class="w-3/4 text-60">Écart pupillaire</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.ecart}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.ecart}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Hauteur de montage</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.hMontage}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.hMontage}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Hauteur calibrée</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.hCalibre}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.hCalibre}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Largeur du calibre</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.lCalibre}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.lCalibre}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Angle pantoscopique</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.aPant}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.aPant}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Galbe de la monture</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.gMonture}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.gMonture}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Distance verre - oeil</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.dVerre}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.dVerre}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Distance de lecture</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.dLecture}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.dLecture}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Diamètre</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.diametre}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.diametre}}</div>
                  </div>
                </div>

                <div class="flex items-center mt-3">
                  <div class="w-3/4 text-60">Pont</div>
                  <div class="flex w-1/4">
                    <div class="w-1/2 text-right">{{devis.estimate.clientMeasures.od.pont}}</div>
                    <div class="w-1/2 text-right ml-4">{{devis.estimate.clientMeasures.og.pont}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bloc bg-white rounded-6 p-6 mt-6 text-left">
            <div class="text-c16 text-90">
              Commercial externe
            </div>

            <div class="mt-4 flex items-center blocCom p-3">
              <icon
                  :data="icons.user"
                  height="35"
                  width="35"
                  class="cursor-pointer"
                  original
              />
              <div class="ml-3 text-left text-c16" v-if="devis.estimate.commercial !== null">
                {{devis.estimate.commercial.fullname}}
              </div>
            </div>
          </div>

          <div>
            <boutonZone @payement="retourPayement" :donnes="devis" @assurance="activeAssurance = true"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eye from '../../../assets/icons/eye.svg'
import edit from '../../../assets/icons/edit.svg'
import bouton from '../../helper/add/button'
import add from '../../../assets/icons/add2.svg'
import user from '../../../assets/icons/user-circle.svg'
import update from '../../../assets/icons/update.svg'
import articleListe from './articleListe'
import ordonnanceListe from './ordonnanceListe'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import labelName from '../../helper/add/nameLabel'
import medecin from './medecinSession'
import boutonZone from './boutonZone'
import paiement from '../../popup/payement'
import assurance from '../../popup/priseEnCharge'
import successPaye from '../../popup/successPaye'
import validation from './validation'
import assur from './assuranceZone'
import mesure from '../../popup/priseDeMesure'
import document from '../../../assets/icons/doc.svg'
import newmesure from '../../popup/addMesure'
import coupon from "@/component/popup/coupon";

export default {
  name: "Index",

  components: {
    newmesure,
    bouton,
    labelName,
    articleListe,
    ordonnanceListe,
    medecin,
    boutonZone,
    paiement,
    assurance,
    successPaye,
    validation,
    assur,
    mesure,
    coupon
    // diviser
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        eye,
        add,
        update,
        user,
        edit,
        document
      },
      client: [],
      valueClient: null,
      activeDetail: false,
      activeClient: false,
      charge: false,
      dataClient: [],
      expiration: null,
      startDate: null,
      devis: null,
      medecin: ['ABOU Rolland', 'Merille SODA', 'Cédric BOUTAN', 'Vincent MOUNCI'],
      activePaye: false,
      activeAssurance: false,
      activeSuccess: false,
      activeMesure: false,
      activCoupon: false
    }
  },

  computed: {
    amount: function () {
      let data = this.devis.articles
      let amount = 0
      if (data !== null) {
        for (let o in data) {
          amount = amount + (parseInt(data[o].price.amount) * parseInt(data[o].quantity))
        }
      }
      return amount.toLocaleString()
    },

    calcule: function () {
      let data = this.devis.payments
      let amount = 0
      if (data !== null) {
        for (let o in data) {
          amount = amount + parseInt(data[o].amount)
        }
      }
      return amount.toLocaleString()
    }
  },

  created () {
    this.devis = this.donnes
    this.valueClient = this.devis
    console.log(this.devis)
    this.startDate = new Date()
    if (this.devis !== null) {
      this.startDate = new Date(this.devis.estimate.created_at)
      this.expiration = new Date(this.devis.estimate.expireDate)
    }
  },

  methods: {
    expirationDate (date) {
      const temps = 10 * (1000 * 3600 * 24)
      const expire = new Date(date).getTime() + temps
      return new Date(expire).toLocaleDateString()
    },

    retourPayement (answer) {
      this.activePaye = answer
    },

    openUpload (url) {
      window.open(url, "_blank");
    },

    retourMesure (answer) {
      this.activeMesure = false
      if (answer) {
        this.$emit('oga', true)
      }
    },

    retourPaye (answer) {
      if (answer) {
        this.activeSuccess = true
      }
      this.activePaye = false
    },

    conversion(index) {
      return new Date(index).toLocaleDateString()
    },

    retourAssurance (answer) {
      this.activeAssurance = answer
    },

    retourSuccess (answer) {
      this.activeSuccess = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.diviser{
  height: 2px;
  background-color: #DCDCE4;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.mouv{
  margin-top: -57px;
  margin-bottom: 60px;
}
.premier {
  width: 70%;
}
.second {
  width: 30%;
}

.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
</style>
