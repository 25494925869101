<template>
  <div class="text-left">
    <validation :activation="activeAlert" v-if="activeAlert" :info="donnes" :donne="isAccept" @oga="retourAlert"/>
      <div class="note p-4 bg-white flex">
        <div class="w-2/3">
          <div class="flex w-1/2">
<!--            <bouton label="Validation en attente" size="10px" height="25px" background="rgba(251, 167, 5, 0.2)" color="#FBA705" class="w-1/3 mr-6"/>-->
            <bouton label="Technique" size="10px" height="25px" background="#CEECEB" color="#099E9C" class="w-1/4"/>
          </div>

          <div class="text-c24 font-c5 mt-4">
            Commande Nº {{donnes.reference}} | <span class="text-60">Péniche </span> {{donnes.houseboat}}
          </div>
        </div>

        <div class="w-1/3 flex justify-end items-center" v-if="donnes.state === 'TECHNIQUE'">
          <bouton label="Rejeter" size="18px" height="54px" background="#F82626" radius="5px" color="#fff" class="w-1/2 mr-6"  @oga="isAccept = false; activeAlert = true "/>
          <bouton label="Valider" size="18px" height="54px" background="#2B9E7C" radius="5px" color="#fff" class="w-1/2" @oga="isAccept = true; activeAlert = true "/>
        </div>

      </div>
  </div>
</template>

<script>
import valid from '../../../assets/icons/active.svg'
import bouton from '../../helper/add/button'
import validation from '../../popup/validation'

export default {
  name: "Index",

  components: {
    bouton,
    validation
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    position: {
      type: Number,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      icons: {
        valid
      },
      activeAlert: false,
      isAccept: false,
      selectedItem: null,
      activePeniche: false
    }
  },

  created () {
  },

  methods: {
    retourAlert (answer) {
      if (answer !== false) {
        this.selectedItem = answer
      }
      this.activeAlert = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: transparent;
}
.note{
  border-radius: 5px;
  border-left: 5px solid #FBA705
}
.base{
  border-left: 1px solid #C4C4C4;
}
.bord{
  border-left: 1px solid #2B9E7C;
}
.rond {
  border: 3px solid #C4C4C4;
}
</style>
